import { createEffect, createEvent, createStore, sample } from "effector";
import restClient from "../../api/rest-client";
import {
  LotteriesIndexPayload,
  LotteriesIndexResponse,
  MainPageLotteriesList,
  TicketsBalance,
  TournamentListItem,
  TournamentDetails,
  TournamentScope,
  TournamentType,
  TournamentDetailsResponse,
  WithdrawHistoryItem,
  WithdrawHistoryResponse,
} from "./tourmanets.interfaces";
import {
  mainPageListDefaultValue,
  ticketsDefaultValue,
  tournamentDetailsDefaultValue,
} from "./tournaments.constants";
import { $language } from "../../utils/i18n";
import { notify } from "../../module/toast/notify";
import i18next from "i18next";
import { amplitudeController } from "../../module/amplitude";

export const $mainPageLotteriesList = createStore<MainPageLotteriesList>(
  mainPageListDefaultValue,
);
export const $ticketsBalance = createStore<TicketsBalance>(ticketsDefaultValue);
export const $openedTournament = createStore<TournamentDetails>(
  tournamentDetailsDefaultValue,
);
export const $withdrawHistory = createStore<WithdrawHistoryItem[]>([]);
export const $withdrawBalance = createStore<string>("0");
export const $allWithdrawItemsFetched = createStore<boolean>(false);
export const $allTournamentsHistoryFetched = createStore<boolean>(false);
export const $tournamentsHistoryList = createStore<TournamentListItem[]>([]);
export const $showWithdrawModal = createStore<boolean>(false);
export const $showSingleTournamentLoading = createStore<boolean>(false);
export const $widthdrawError = createStore<string>("");
export const $showHistoryModal = createStore<boolean>(false);
export const $widthToCancelId = createStore<string>("");

export const fetchMainPageLotteriesList = createEvent();
export const setOpenedTournament = createEvent<TournamentListItem>();
export const getSingleTournament = createEvent<string>();
export const lotteryParticipate = createEvent<string>();
export const getWidthdrawMoney = createEvent<string>();
export const fetchTournamentsHistory = createEvent();
export const getWithdrawHistory = createEvent();
export const toggleWithdrawModal = createEvent();
export const clearOpenedTournament = createEvent();
export const clearWidthdrawError = createEvent();
export const setWidthdrawError = createEvent<string>();
export const fetchWithdrawBalance = createEvent();
export const setShowHistoryModal = createEvent<boolean>();
export const setWidthToCancelId = createEvent<string>();
export const cancelWithdraw = createEvent<string>();
export const fetchSingleTournamentFx = createEffect(async (id: string) => {
  const response: TournamentDetailsResponse = await restClient.post(
    `lotteries/lottery-info`,
    JSON.stringify({ lotteryId: id, locale: $language.getState() }),
  );
  return response;
});

sample({
  clock: setWidthToCancelId,
  target: $widthToCancelId,
});

const fetchLotteries = async (payload: LotteriesIndexPayload) => {
  return await restClient.post("lotteries/index", JSON.stringify(payload));
};

export const fetchMainPageLotteriesListFx = createEffect(async () => {
  const response: LotteriesIndexResponse = await fetchLotteries({
    scopes: [TournamentScope.OneOldOneActiveOneUpcomingPerType],
    offset: 0,
    limit: 100,
    locale: $language.getState(),
  });
  return response;
});

export const fetchTournamentsHistoryFx = createEffect(
  async (offset: number) => {
    const response: LotteriesIndexResponse = await fetchLotteries({
      scopes: [TournamentScope.My, TournamentScope.Finished],
      offset,
      limit: 40,
      locale: $language.getState(),
    });
    return response;
  },
);

export const fetchLotteryParticipateFx = createEffect(async (id: string) => {
  const data: TournamentDetailsResponse = await restClient.post(
    `lotteries/participate`,
    JSON.stringify({ lotteryId: id, locale: $language.getState() }),
  );
  return data;
});

export const cancelWithdrawFx = createEffect(async (id: string) => {
  const response: { balance: string } = await restClient.post(
    `lotteries/cancel-withdraw`,
    JSON.stringify({ withdrawId: id }),
  );
  return response;
});

export const fetchWithdrawBalanceFx = createEffect(async () => {
  const response: LotteriesIndexResponse = await fetchLotteries({
    scopes: [TournamentScope.All],
    offset: 0,
    limit: 1,
    locale: $language.getState(),
  });
  return response;
});

export const fetchDoWithdrawBalanceFx = createEffect(
  async ({ amount, address }: { amount: string; address: string }) => {
    console.log(amount, address);
    const response: {
      balance: string;
    } = await restClient.post(
      `lotteries/withdraw`,
      JSON.stringify({ amount, address }),
    );
    return response;
  },
);

const fetchWithdrawHistoryFx = createEffect(async (offset: number) => {
  const response: WithdrawHistoryResponse = await restClient.post(
    `lotteries/withdraws-history`,
    JSON.stringify({ offset, limit: 40 }),
  );
  return response;
});

const mergeWithdrawHistory = (
  history: WithdrawHistoryItem[],
  newHistory: WithdrawHistoryItem[],
) => {
  const historyMap = new Map(history.map((item) => [item.id, item]));
  newHistory.forEach((item) => historyMap.set(item.id, item));
  const mergedArray: WithdrawHistoryItem[] = Array.from(
    historyMap.values(),
  ).map((item) => item);
  return mergedArray;
};

sample({
  source: {
    allHistoryFetched: $allWithdrawItemsFetched,
    historyList: $withdrawHistory,
  },
  clock: getWithdrawHistory,
  filter: ({ allHistoryFetched }) => !allHistoryFetched,
  fn: ({ historyList }) => historyList.length,
  target: fetchWithdrawHistoryFx,
});

sample({
  clock: clearWidthdrawError,
  fn: () => "",
  target: $widthdrawError,
});

sample({
  clock: setWidthdrawError,
  target: $widthdrawError,
});

sample({
  clock: clearOpenedTournament,
  target: $openedTournament,
  fn: () => tournamentDetailsDefaultValue,
});

sample({
  clock: cancelWithdrawFx.doneData,
  fn: () => false,
  target: $showHistoryModal,
});

sample({
  clock: cancelWithdraw,
  target: cancelWithdrawFx,
});

sample({
  clock: cancelWithdrawFx.doneData,
  fn: () => "",
  target: $widthToCancelId,
});

sample({
  clock: cancelWithdrawFx.doneData,
  fn: () => false,
  target: [
    $withdrawHistory.reinit,
    $allWithdrawItemsFetched.reinit,
    fetchWithdrawBalanceFx,
    getWithdrawHistory,
  ],
});

sample({
  source: $showWithdrawModal,
  clock: toggleWithdrawModal,
  fn: (show) => !show,
  target: $showWithdrawModal,
});

sample({
  clock: setShowHistoryModal,
  target: $showHistoryModal,
});

sample({
  clock: setShowHistoryModal,
  filter: (show) => !show,
  fn: () => "",
  target: $widthToCancelId,
});

sample({
  source: $withdrawHistory,
  clock: fetchWithdrawHistoryFx.doneData,
  fn: (history, { withdrawals }) => mergeWithdrawHistory(history, withdrawals),
  target: $withdrawHistory,
});

sample({
  clock: fetchWithdrawHistoryFx.doneData,
  fn: ({ withdrawals }) => withdrawals.length === 0,
  target: $allWithdrawItemsFetched,
});

sample({
  source: $withdrawBalance,
  clock: getWidthdrawMoney,
  fn: (amount, address) => ({ amount, address }),
  target: fetchDoWithdrawBalanceFx,
});

sample({
  clock: fetchDoWithdrawBalanceFx.failData,
  fn: ({ message }) => message,
  target: $widthdrawError,
});

sample({
  clock: fetchDoWithdrawBalanceFx.doneData,
  fn: ({ balance }) => balance,
  target: $withdrawBalance,
});

sample({
  clock: fetchDoWithdrawBalanceFx.doneData,
  fn: () => false,
  target: $showWithdrawModal,
});

sample({
  clock: fetchDoWithdrawBalanceFx.doneData,
  target: [
    $withdrawHistory.reinit,
    $allWithdrawItemsFetched.reinit,
    getWithdrawHistory,
  ],
});

fetchDoWithdrawBalanceFx.failData.watch(() => {
  notify({
    message: i18next.t("common.notification.somethingWrong"),
    type: "error",
  });
});

fetchDoWithdrawBalanceFx.doneData.watch(() => {
  setShowHistoryModal(true);
});

sample({
  source: fetchMainPageLotteriesList,
  target: fetchMainPageLotteriesListFx,
});

const mergeTournaments = (
  tournaments: TournamentListItem[],
  newTournaments: TournamentListItem[],
) => {
  const tournamentsMap = new Map(
    tournaments.map((tournament) => [tournament.id, tournament]),
  );
  newTournaments.forEach((tournament) =>
    tournamentsMap.set(tournament.id, tournament),
  );
  const mergedArray: TournamentListItem[] = Array.from(
    tournamentsMap.values(),
  ).map((tournament) => tournament);
  return mergedArray;
};

sample({
  source: {
    list: $tournamentsHistoryList,
    allFetched: $allTournamentsHistoryFetched,
    inProgess: fetchTournamentsHistoryFx.pending,
  },
  clock: fetchTournamentsHistory,
  filter: ({ allFetched, inProgess }) => !allFetched && !inProgess,
  fn: ({ list }) => list.length,
  target: fetchTournamentsHistoryFx,
});

sample({
  source: $tournamentsHistoryList,
  clock: fetchTournamentsHistoryFx.doneData,
  filter: (_, newTournaments) => newTournaments.lotteries.length > 0,
  fn: (sourceTournaments, newTournaments) =>
    mergeTournaments(sourceTournaments, newTournaments.lotteries),
  target: $tournamentsHistoryList,
});

sample({
  clock: [
    fetchTournamentsHistoryFx.doneData,
    fetchMainPageLotteriesListFx.doneData,
    fetchWithdrawBalanceFx.doneData,
  ],
  fn: ({ balance }) => balance,
  target: $withdrawBalance,
});

sample({
  clock: fetchTournamentsHistoryFx.doneData,
  fn: ({ lotteries }) => lotteries.length === 0,
  target: $allTournamentsHistoryFetched,
});

sample({
  source: lotteryParticipate,
  target: fetchLotteryParticipateFx,
});

sample({
  clock: fetchMainPageLotteriesListFx.doneData,
  fn: ({ lotteries }) => {
    const reducedByType = lotteries
      .sort(
        (a, b) =>
          new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime(),
      )
      .reduce(
        (acc, lottery) => {
          if (!acc[lottery.type]) {
            return acc;
          }
          acc[lottery.type].push(lottery);
          return acc;
        },
        {
          [TournamentType.DailyFree]: [],
          [TournamentType.DailyPaid]: [],
          [TournamentType.WeeklyPaid]: [],
        } as MainPageLotteriesList,
      );
    return reducedByType;
  },
  target: $mainPageLotteriesList,
});

sample({
  clock: [fetchSingleTournamentFx.doneData, fetchLotteryParticipateFx.doneData],
  fn: ({ lottery }) => lottery,
  target: $openedTournament,
});

sample({
  clock: [
    fetchSingleTournamentFx.doneData,
    fetchMainPageLotteriesListFx.doneData,
    fetchLotteryParticipateFx.doneData,
  ],
  fn: ({ tickets }) => ({
    [TournamentType.DailyFree]: tickets.daily_free,
    [TournamentType.DailyPaid]: tickets.daily_paid,
    [TournamentType.WeeklyPaid]: tickets.weekly_paid,
  }),
  target: $ticketsBalance,
});

fetchLotteryParticipateFx.doneData.watch(({ lottery }) => {
  if (lottery.userTicketsCount > 1) {
    notify({
      message: i18next.t("tournament.notification.chancesIncreased"),
      type: "success",
    });
  }
  amplitudeController.trackTriumphParticipation({
    draw_name: lottery.title,
    tickets_used: lottery.entryPrice,
    type: lottery.type,
  });
});

sample({
  clock: getSingleTournament,
  target: fetchSingleTournamentFx,
});

sample({
  source: $openedTournament,
  clock: getSingleTournament,
  filter: (opened, id) => opened.id !== id,
  target: $showSingleTournamentLoading,
  fn: () => true,
});

sample({
  clock: fetchSingleTournamentFx.doneData,
  fn: () => false,
  target: $showSingleTournamentLoading,
});
