import { createEffect, createEvent, createStore, sample } from "effector";

import { $clickerState, $token } from "./auth";

import { Referrer, SetCharacterResponseData } from "./types";
import { router } from "../router/router.tsx";
import restClient from "../api/rest-client.ts";
import { amplitudeController } from "../module/amplitude/index.ts";
import appStorage from "../module/app-storage/index.ts";
import { StorageKeys } from "../module/app-storage/app-storage.constants.ts";
import { RoutePaths } from "../router/routes.ts";
// import envConfig from "../api/config";

export const $character = createStore("zephyr");
export const $referrer = createStore<Referrer | null>(null);
export const $referrerEarned = createStore<number | null>(null);
export const changeCharacter = createEvent<string>();
export const selectCharacter = createEvent();

export const setCharacterFx = createEffect<
  { token: string; character: string },
  SetCharacterResponseData
>(async ({ character }) => {
  // const response = await fetch(`${envConfig.apiUrl}/clicker/set-avatar`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  //   body: JSON.stringify({
  //     avatarId: character,
  //   }),
  // });
  const result = await restClient.post(
    "clicker/set-avatar",
    JSON.stringify({
      avatarId: character,
    }),
  );
  return result;
});
sample({
  source: $character,
  clock: [
    changeCharacter,
    $clickerState.updates.filterMap((clickerState) =>
      clickerState === null ? undefined : clickerState.avatarId,
    ),
  ],
  fn: (character, avatarId) =>
    avatarId === null || avatarId.includes("XX") ? character : avatarId,
  target: $character,
});

sample({
  source: {
    token: $token,
    character: $character,
  },
  clock: selectCharacter,
  target: setCharacterFx,
});
sample({
  clock: setCharacterFx.doneData,
  fn: ({ earned }) => earned,
  target: $referrerEarned,
});
sample({
  clock: setCharacterFx.doneData,
  fn: ({ referrer }) => referrer,
  target: $referrer,
});
setCharacterFx.doneData.watch(({ referrer }) => {
  if (referrer !== null) {
    router.navigate("/onboarding/step7");
  } else {
    const onboardStartDate = new Date(
      appStorage.getItem(StorageKeys.OnboardStartDate) as string,
    );
    const onboardEndDate = new Date();
    const timeDiff = onboardEndDate.getTime() - onboardStartDate.getTime();

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    const onboardTimeSpent = `${hours}h ${minutes}m ${seconds}s`;
    appStorage.removeItem(StorageKeys.OnboardStartDate);
    amplitudeController.trackOnboardingCompleted({
      time_spent: onboardTimeSpent,
      steps_completed: 6,
    });
    router.navigate(RoutePaths.GameTournaments);
  }
});
